import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { setApiRequestCache, deleteApiRequestCache, useAppContext, deleteAllApiRequestCache } from "../store";

type CacheKeyConfig = {
    request: string,
    params?: object
}

const devMode = window.location.hostname === "localhost";

const makeUrl = (endpoint, restBase) => devMode ? `/api/${endpoint}.json` : `${restBase}${endpoint}`;

const requests = {
    entry:  ({ entryId }) => [`entries/${entryId}`],
    pendingEntry:  ({ type }) => [`entries/pending/${type}`],
    entryRange:  ({ range }) => [`entries/range/${range}`],
    form: ({ formId }) => [`forms/${formId}`],
    formSubmission: ({ submissionId }) => [`submissions/${submissionId}`],
    toolsMeta: () => [`views/tools`],
    ldContent: (params) => ['ldcontent?' + (new URLSearchParams(params)).toString()],
    ldUserCourseInfo: (params) => ['ldusercourseinfo?'+(new URLSearchParams(params)).toString()],
    userStory: ({ storyKey }) => [`stories/${storyKey}`]
}

const mutationRequests = {
    createSubmission: ({ formId }, data) => [`forms/${formId}/submissions`, { method: "POST", body: JSON.stringify(data) }],
    updateSubmission: ({ submissionId }, data) => [`submissions/${submissionId}`, { method: "PUT", body: JSON.stringify(data) }],
    markLdContentComplete: ( params, data ) => [`ldactivity`, { method: "POST", body: JSON.stringify(data) }],
    setStoryChapterStatus: ( { storyKey, chapterKey }, data ) => [`stories/${storyKey}/chapters/${chapterKey}`, { method: "PUT", body: JSON.stringify(data) }],
}

const fileRequests = {
    entriesExport: ({ formId }, data) => [`entries/export`, { method: "POST", body: JSON.stringify(data) }],
    updateSubmission: ({ submissionId }, data) => [`submissions/${submissionId}`, { method: "PUT", body: JSON.stringify(data) }]
}

const pendingCache = new Map();

// Make requests if not using cache, the cache exists, or request for the cache is already pending.
const shouldRequest = ( stall, request, cache ) => !stall && (!cache[request] && !pendingCache.has(request));

const makeRequestOptions = ( config, { headers: optionHeaders, ...options } : RequestInit, signal = undefined ) => {
    const headers = {
        ...( optionHeaders || {} ),
        ...( options.body ? { "Content-Type": "application/json" } : {} ),
        ...(config.nonce ? { "X-WP-Nonce": config.nonce }: {}),
    }

    return {
        headers,
        ...options,
        ...(signal ? { signal } : {})
    }
}

const createCacheKey = ({
    request,
    params = {}
}: CacheKeyConfig) => [request, ...Object.entries(params).filter(([,value])=>value).map(([,value])=>value)].join('.') + "v2"

export const useApiCacheKey = ( config: false|CacheKeyConfig ) => {
    return useMemo(() => config && createCacheKey(config), [config]);
}

export const useApiCacheKeys = ( configs: CacheKeyConfig[] ) => {
    return useMemo(() => configs.map((config) => createCacheKey(config)), [configs]);
}

const apiMutation = async ({
    request,
    params = {},
    data,
    config
}) : Promise<[ boolean, any, number ]> => {
    try {
        if( devMode ) {
            console.log(data);
            return [ true, {}, 0 ];
        }
        const [ endpoint, endpointOptions = {} ] = mutationRequests[request]( params, data );
        const result = await fetch(makeUrl(endpoint, config.restBase), makeRequestOptions(config, endpointOptions));
        const body = await result.json();
        return [ result.ok, body, result.status ];
    } catch(err) {
        const error = err as DOMException;
        const isAbort = error.code === error.ABORT_ERR;
        // console.log("Fetch failure:", error);
        return [ false, null, isAbort ? 0 : 500 ];
    }
}

export const useApiMutator = () => {
    const [ { config } ] = useAppContext();

    const mutator = async ({
        request,
        params = {},
        data = {}
    }) => {
        return apiMutation({
            request,
            params,
            data,
            config
        });
    }

    return mutator;
};

export const useApiMutation = ({
    request,
    params = {}
}) => {

    const [ { config } ] = useAppContext();

    const makeRequest = useCallback(async ( data ) : Promise<[ boolean, any, number ]> => {
        return apiMutation({
            request,
            params,
            data,
            config
        });
    },[config, request, params]);

    return makeRequest;

}

export const useApiCacheClear = ({
    request,
    params = {}
}) => {
    const cacheKey = useMemo(() => createCacheKey({request,params}),[]);
    const [ , dispatch ] = useAppContext();
    return useCallback(() => {
        dispatch( deleteApiRequestCache, { request: cacheKey } );
    },[cacheKey])
}

// TODO Need an alternate stragety to refetch the data, but not actually delete the cache.
export const useApiCacheClearAll = () => {
    const [ , dispatch ] = useAppContext();
    return useCallback(() => {
        dispatch( deleteAllApiRequestCache );
    },[])
}

export const useApi = ({
    stall = false,
    request,
    initValue = undefined,
    params = {},
    data = {},
    useCache = false
}) => {

    const [ { apiCache, config }, dispatch ] = useAppContext();

    const makeRequest = useCallback(async ( endpoint, endpointOptions = {} ) => {
        try {
            const result = await fetch(makeUrl(endpoint, config.restBase), makeRequestOptions(config, endpointOptions));
            const body = await result.json();
            return [ result.ok, body, result.status ];
        } catch(err) {
            const error = err as DOMException;
            const isAbort = error.code === error.ABORT_ERR;
            // console.log("Fetch failure:", error);
            return [ false, null, isAbort ? 0 : 500 ];
        }
    },[config]);

    const cacheKey = useMemo(() => createCacheKey({request,params}),[stall,params]);
    const prevCacheKey = useRef(cacheKey);

    const makeState = () =>
        !stall && useCache && (cacheKey in apiCache)
            ? apiCache[cacheKey]
            : [ null, initValue, cacheKey ];

    const [state, setState] = useState( makeState );
    const [ ok ] = state;

    useEffect(() => {
        if( cacheKey !== prevCacheKey.current ) {
            prevCacheKey.current=cacheKey;
            setState( makeState() );
        }
    },[cacheKey])

    // Handle updating state from cache if `cache` = true
    useEffect(() => {

        if( stall || !useCache || !apiCache[cacheKey] ) return;

        setState([ ...apiCache[cacheKey], cacheKey]);

    }, [stall, useCache, ok, apiCache]);
    
    // Main request effect handler
    useEffect(() => {

        // Cache handler
        if(!useCache) return;

        if( shouldRequest( stall, cacheKey, apiCache ) ) {
            (async () => {
                pendingCache.set(cacheKey, true);
                const result = await makeRequest( requests[request]( params, data ) );
                dispatch( setApiRequestCache, { request: cacheKey, result } );
                pendingCache.delete(cacheKey);
            })();
        }

    }, [stall, cacheKey, request, apiCache]);

    useEffect(() => {

        // No Cache Hander
        if(useCache || stall) return;

        (async () => {
            setState( await makeRequest( requests[request]( params, data ) ) );
        })();

    }, [stall, request, cacheKey]);

    return state;

}

export const useLazyApi = ({
    request,
    useCache = false
}) => {

    const [ { apiCache, config }, dispatch ] = useAppContext();

    const makeRequest = useCallback(async ( params = {}, data = {}, signal : undefined|AbortSignal = undefined ) => {

        const cacheKey = useCache && createCacheKey({request,params});

        if(cacheKey && cacheKey in apiCache) return [ ...apiCache[cacheKey], cacheKey];

        try {
            const [ endpoint, endpointOptions = {} ] = requests[request]( params, data );
            const response = await fetch(makeUrl(endpoint, config.restBase), makeRequestOptions(config, endpointOptions, signal));
            const body = await response.json();
            const result =  [ response.ok, body, response.status ];
            dispatch( setApiRequestCache, { request: cacheKey, result } );
            return result;
        } catch(err) {
            const error = err as DOMException;
            const isAbort = error.code === error.ABORT_ERR;
            // console.log("Fetch failure:", error);
            return [ false, null, isAbort ? 0 : 500 ];
        }
    },[config, request, apiCache, useCache]);

    return makeRequest;
}