import React, { createContext, useReducer, useEffect, useContext } from "react";
import { actions, moduleState, reducerModules } from "./internals";

export * from "./actions";

// TODO Update actions/reducer/context to follow this approach for TypeScript
// @see https://dev.to/hellomuthu23/how-to-use-usereducer-and-usecontext-hooks-with-typescript-in-react-14d1

export const AppContext = createContext<ProvidedAppContext>([
    {
        reducerModuleState: {},
        apiCache: {},
        config: {} as AppConfig,
        viewRange: "day",
        pickActivityType: "create"
    },
    (action, options = undefined) => { }
]);

export const AppContextProvider = ({ config, children }) => {

    const reducer = (state, updates: DispatchAction[]) => {
        return updates.reduce((state, { action, options }) => {
            if (!(actions.has(action))) throw new Error('Invalid reducer action');

            return {
                ...state,
                ...(actions.get(action)(options, state))
            }
        }, state);
    }

    const [store, dispatch] = useReducer(reducer, {
        reducerModuleState: {},
        apiCache: {},
        config,
        viewRange: "day",
        pickActivityType: "create"
    });

    const dispatchWithOptions: Dispatch = (action, options) => dispatch(Array.isArray(action) ? action.map(([action, options]) => ({ action, options })) : [{ action, options }]);

    return <AppContext.Provider value={[store, dispatchWithOptions]}>{children}</AppContext.Provider>

}

export const useAppContext = (): ProvidedAppContext => {
    return useContext<ProvidedAppContext>(AppContext);
}

export const useLocale = (): Locale => {
    const [{config: { lang }}] =  useContext<ProvidedAppContext>(AppContext);

    return lang;
}

export const useReducerModule = (module) => {

    const [{ reducerModuleState }, dispatch] = useContext(AppContext);

    useEffect(() => {

        if (!(module in moduleState)) {

            moduleState[module] = false;
            dispatch("setReducerModuleState", { module, state: false });

            (async () => {
                const { actions: modActions } = await reducerModules[module]();

                if (actions) for (const actionName in modActions) actions.set(actionName, modActions[actionName]);

                moduleState[module] = true;
                dispatch("setReducerModuleState", { module, state: true });
            })();

        }

    }, [module]);

    return reducerModuleState[module];

}