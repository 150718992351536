export const setViewRange = "setViewRange";
export const setPickActivityType = "setPickActivityType";
export const setReducerModuleState = "setReducerModuleState";
export const setApiRequestCache = "setApiRequestCache";
export const prependApiRequestCacheDataList = "prependApiRequestCacheDataList";
export const prependApiRequestCachesDataList = "prependApiRequestCachesDataList";
export const appendApiRequestCacheDataList = "appendApiRequestCacheDataList";
export const appendApiRequestCachesDataList = "appendApiRequestCachesDataList";
export const updateApiRequestCacheDataListItem = "updateApiRequestCacheDataListItem";
export const updateApiRequestCachesDataListItem = "updateApiRequestCacheDataListItem";
export const deleteApiRequestCache = "deleteApiRequestCache";
export const deleteApiRequestCaches = "deleteApiRequestCaches";
export const deleteAllApiRequestCache = "deleteAllApiRequestCache";